.titel-text {
  display: flex;
  font-size: 30px;
  margin: 60px 0;
  font-weight: bold;
  padding: 20px;
  box-sizing: border-box;
  border: 10px lightgreen; /* Add this line to create a border */
}


.highlighted-text {
  font-size: 30px;
  margin: 20px 0;
  font-weight: bold;
  padding: 20px;
  background: #f9f9f9;
}

h6 {
  font-size: 15px;
  margin: 20px 0;
  padding: 20px;
  background: #f9f9f9;
  color: rgb(29, 152, 29);
}


