.header-section {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100; /* Ensure the header appears above other content */
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 15px; /* Reduced padding for smaller screens */
  background-color: #f8f9fa;
  height: 20px; /* Set a fixed height for the header */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Optional: add a shadow for better visibility */
}

.navbar-nav .nav-item .nav-link {
  color: black;
  text-decoration: none;
}

.navbar-nav .nav-item .nav-link:hover {
  color: green;
}

body {
  padding-top: 60px; /* Match the height of the header */
  margin: 0; /* Ensure no default margin */
  font-family: Arial, sans-serif; /* Add a default font-family */
}

.nav-item {
  margin-left: 10px;
  margin-right: 10px;
}

.navbar-brand .logo {
  width: 100px;
  height: auto;
}

@media (max-width: 480px) {
  .navbar-nav {
    flex-direction: column;
    width: 100%;
  }

  .navbar-nav .nav-item {
    margin: 5px 0; /* Add margin for spacing between nav items */
  }
}

.nav-link.active {
  border-bottom: 2px solid rgb(46, 124, 37); /* Green border for the active NavLink */
}

.btn-success {
  margin-left: 10px;
}

.navbar {
  height: 80px; /* Adjust the height of the header */
  padding: 10px 15px; /* Adjust the padding inside the header */
}

.navbar-brand .logo {
  width: 80px; /* Adjust the width of the logo */
  height: auto; /* Maintain aspect ratio */
}

.navbar-nav .nav-item .nav-link {
  font-size: 16px; /* Adjust the font size of the navigation links */
}

.navbar-collapse {
  background-color: white; /* Set background color to white */
  padding: 10px; /* Optional: add some padding */
}

.collapse.show {
  display: flex !important;
}
