.carren-section {
    padding: 30px;
    padding-top: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;
    background: linear-gradient(to right, #000308, #FBFCFF);
    flex-wrap: wrap; /* Allow wrapping for better responsiveness */
}

.text-section {
    width: 60%;
    padding: 0 32px;
    margin-right: 500px;
}

.carrer-headline {
    margin-bottom: 12px;
    color: rgb(17, 151, 20);
    font-family: 'Rubik', sans-serif;
    font-size: 22px;
    font-weight: bold;
    letter-spacing: .7px;
    animation: fadeIn 1s ease-in;
}

.carrer-title {
    width: 500px;
    color: rgb(17, 151, 20);
    font-family: 'Poppins', sans-serif;
    font-size: 40px;
    font-weight: bold;
    white-space: nowrap;
}

.career-descritpion {
    color: rgb(0, 0, 0);
    font-family: 'Rubik', sans-serif;
    font-size: 18px;
    letter-spacing: .7px;
    line-height: 1.6rem;
    animation: slideInFromLeft 0.5s ease-out;
}

.career-descritpion0,
.career-description1,
.career-description2,
.career-description3 {
    display: flex;
    align-items: flex-start;
    color: #000;
    border-bottom-left-radius: 2px;
    margin: 10px;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(255, 10, 10, 0.1);
}

.career-description2 {
    flex-direction: row-reverse;
}

.description-image {
    width: 500px;
    height: 400px;
    margin-left: 20px;
}

.text-container {
    flex: 1;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes slideInFromLeft {
    0% {
        transform: translateX(-100%);
        opacity: 0;
    }
    100% {
        transform: translateX(0);
        opacity: 1;
    }
}

.carrer-what {
    margin: 10px 0 10px 30px;
    font-size: 30px;
    font-weight: bold;
    text-decoration: underline;
    text-decoration-color: #099a43;
    animation: fadeIn 1s ease-in;
}

.carrer-what1 {
    margin: 10px 0 10px 30px;
    font-size: 15px;
    animation: fadeIn 1s ease-in;
}

@media screen and (max-width: 900px) {
    .carren-section {
        flex-direction: column;
        align-items: flex-start;
        padding: 20px;
    }
    .text-section {
        width: 100%;
        margin-right: 0;
    }
    .carrer-title,
    .description-image {
        width: 90%;

    }
    .career-descritpion0,
    .career-description1,
    .career-description2,
    .career-description3 {
        flex-direction: column;
        align-items: flex-start;
    }
}

@media screen and (max-width: 600px) {
    .carren-section {
        padding: 10px;
    }
    .text-section {
        padding: 0 10px;
    }
    .carrer-headline {
        font-size: 20px;
    }
    .carrer-title {
        font-size: 28px;
        margin-top: -100px;

    }
    .career-descritpion,
    .carrer-what,
    .carrer-what1 {
        font-size: 16px;
    }
    .career-description1,
    .career-description2,
    .career-description3 {
        padding: 10px;
    }
    .description-image {
        height: auto;
    }
}
