.info-section {
  text-align: center;
  background-color: white;
  padding: 32px;
  padding-top: 70px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 24px;
  background: linear-gradient(to right, #ECF2FF, #FBFCFF);
}

.service-image {
  width: 50%;
  max-width: 600px;
  height: auto;
}

.button-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.services-content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
}

.info-title h8 {
  font-size: 16px;
  color: #047345;
  text-transform: uppercase;
  margin-bottom: 10px;
  padding-right: 290px;
}

.info-title1 h3 {
  font-size: 24px;
  font-weight: bold;
  color: #555;
  margin-top: 10px;
  padding-right: 250px;
}

.btn {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 12px;
  margin: 5px;
  transition: background-color 0.3s ease;
}

.btn-primary {
  color: white;
}

.btn-success {
  color: white;
}

.btn-success:hover {
  background-color: #218838;
}

.text-effect {
  animation: textFadeIn 0.5s ease-out forwards;
}

@keyframes textFadeIn {
  0% {
    opacity: 0;
    transform: translateY(-10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@media (max-width: 768px) {
  .info-section {
    flex-direction: column;
    align-items: center;
  }

  .service-image {
    width: 100%;
  }

  .info-title h8, .info-title1 h3 {
    padding-right: 0;
    text-align: center;
  }

  .button-container {
    margin-top: 10px;
    flex-wrap: wrap;
  }

  .services-content {
    flex-direction: column;
  }

  .btn {
    width: 100%;
    max-width: 300px;
  }
}
