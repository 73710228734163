.seminar-heading {
    margin-top: 2rem;
    background: #000000;
    color: #ffffff;
    padding: 1rem;
}

.seminar-bg {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 400px;
    overflow: auto;
}

.topics-grid {
    margin-top: 2rem;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 5px;
}

.topic-item {
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    padding: 20px;
    border-radius: 8px;
}



.seminar-heading2 {
    margin-top: 1rem;
    background: #1b1b2c;
    color: #ffffff;
    padding: 1rem;
    text-align: center;
}

.seminar-heading3 {
    margin-top: 1rem;
    background: #181851;
    color: #ffffff;
    padding: 1rem;
    text-align: center;
}

.scroll-wrapper {
    overflow-x: auto;
    white-space: nowrap;
    padding: 20px;

}

.Seminarbg-grid {
    grid-template-columns: repeat(3, 1fr);
    gap: 5px;
    text-align: center;
}

.Seminarbg-item {
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    padding: 4px;
    border-radius: 8px;
    display: inline-block;
}

.Seminarbg-item:hover {
    transform: scale(1.05);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

.Seminarbg-grid3 {
    grid-template-columns: repeat(3, 1fr);
    text-align: center;
}

.Seminarbg-item3 {
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    padding: 4px;
    border-radius: 8px;
    display: inline-block;
}
.proposal-grid {
    margin-top: 2rem;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
    margin-left: 1rem;
    margin-right: 1rem;

}

.button-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
}
.seminar-bg1 {
    width: 100%;
    height: 18rem;
}
.seminar-bg2 {
    width: 100%;
    height: 15rem;
}

.seminar-bg3 {
    width: 100%;
    height: 20rem;
}

.certification-heading2 {
    margin-top: 1rem;
    background: rgb(238, 238, 238);
    color: #2c3e50;
    padding: 1rem;
    text-align: center;
    font-size: large;
    font-weight: 600;
    border: #000000;
    border-radius: 0.5rem;
}

.spaced-list {
    margin-top: 20px;
    /* Adjust the value as needed */
    font-size: large;
}

.spaced-list li {
    margin-bottom: 15px;
    /* Adjust the value as needed */
}

.certification-text {
    margin: 1rem 2rem;
    font-size: large;
}

.certification-heading3 {
    padding: 1rem;
    font-size: large;
    text-align: left;
}

.certification-heading4 {
    font-size: 1.5rem;
    text-align: center;
}

.certification-button{
    font-size: 40px;
    text-align: center;
}
.video-container {
    position: relative;
    width: 100%;
    max-width: 1052px;
    padding-bottom: 35.07%; /* Aspect ratio: height / width * 100% */
    height: 0;
    overflow: hidden;
    margin-bottom: 4rem;
  }
  
  .video-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
  }
  .seminar-articles{
    margin-left: 1rem;
    margin-right: 1rem;

  }
  
@media (max-width: 768px) {
    .seminar-bg {
        width: 100%;
        height: auto;
    }

    .topics-grid {
        grid-template-columns: repeat(1, 1fr);
    }

    .proposal-grid {

        grid-template-columns: repeat(1, 1fr);

    }
    .button-grid {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
    }
   
}