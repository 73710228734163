.relative {
    position: relative;
    margin: 40px;
  }
  
  .p-title {
    font-weight: bold;
    font-size: 24px;
  }
  
  .small-border {
    border-bottom: 2px solid #038f39;
    width: 50px;
    margin-top: 10px;
  }
  
  .sm-left {
    margin-left: 0;
  }
  
  blockquote {
    font-size: 16px;
    margin: 20px 0;
    font-weight: bold;
    padding: 20px;
    background: #f9f9f9;
    border-left: 5px solid #065e45;
  }
  
  .by {
    display: block;
    margin-top: 10px;
    font-size: 14px;
    font-style: italic;
  }
  