.contact-us-container {
  background-image: url('../Assets/contectbg-1.png');
  background-size: cover;
  background-position: center;
  color: #fff;
  padding: 50px 0;
}

.contact-info {
  background-color: rgba(0, 0, 0, 0.5); /* Opaque background */
  padding: 20px;
  border-radius: 5px;
}

.contact-form {
  background-color: rgba(0, 0, 0, 0.5); /* Opaque background */
  padding: 20px;
  border-radius: 5px;
}

.form-group {
  margin-bottom: 10px;
}

label {
  display: block;
  margin-bottom: 5px;
}

input,
textarea {
  width: 100%;
  padding: 5px;
  border-radius: 3px;
  border: 1px solid #ccc;
}

button {
  padding: 8px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 3px;
  cursor: pointer;
}

button:hover {
  background-color: #0056b3;
}



.contact-info-box {
  background-color: #a7aea5;
  padding: 10px;
  margin-bottom: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(54, 50, 50, 0.1);
}

.contact-info-box h3 {
  margin-bottom: 10px;
}

.contact-info {
  color: #ff5733; /* Change this to your desired color */
}
