.about-bg {
    background-image: url('../Assets/aboutbg2.png'); /* Update with your image path */
    background-size: cover;
    background-position: center;
    height: 50vh; /* Adjust height as needed */
    display: flex;
    align-items: center;
    justify-content: center;
}

@keyframes slideInRight {
    from {
        opacity: 0;
        transform: translateX(100%);
    }
    to {
        opacity: 1;
        transform: translateX(0);
    }
}

.about-section {
    padding: 32px;
    display: flex;
    padding-top: 70px;
    justify-content: center;
    align-items: flex-start;
    gap: 24px;
    background: linear-gradient(to right, #ECF2FF, #FBFCFF);
}

.about-image-content {
    width: 50%;
    max-width: 600px;
}

.about-image1 {
    width: 100%;
    height: 500px;
}

.about-text-content {
    width: 50%;
}

.about-title {
    text-align: left;
    margin-left: 0;
    font-family: 'Poppins', sans-serif;
    font-size: 40px;
    font-weight: bold;
}

.about-title > span {
    position: relative;
}

.about-title span::before {
    content: '';
    position: absolute;
    width: 75%;
    height: 4px;
    background-color: #2cd055;
    border-radius: 8px;
    left: 0;
    bottom: -12px;
}

.about-description {
    margin: 32px 0;
    margin-left: 50px;
    margin-right: 40px;
    color: #4b4d4b;
    font-family: 'Rubik', sans-serif;
    font-size: 20px;
    letter-spacing: 0.9px;
    line-height: 1.7rem;
}

.about-text-title {
    margin: 0 0 24px 0;
    color: #4b4d4b;
    font-family: 'Poppins', sans-serif;
    font-size: 24px;
    font-weight: bold;
    letter-spacing: .7px;
}

.about-text-step {
    margin: 16px 0;
    opacity: 0;
    animation: slideInRight 0.5s ease-in-out forwards;
}

.about-text-step:nth-child(3) {
    animation-delay: 0.5s;
}

.about-text-step:nth-child(4) {
    animation-delay: 1s;
}

.about-text-step:nth-child(5) {
    animation-delay: 1.5s;
}

.about-text-sTitle {
    font-family: 'Rubik', sans-serif;
    font-size: 18px;
    font-weight: bold;
    letter-spacing: .7px;
}

.about-text-description {
    margin: 8px 24px;
    color: #4d4b4b;
    font-family: 'Rubik', sans-serif;
    letter-spacing: .7px;
    line-height: 1.6rem;
}

.fa-icon {
    color: #10af10;
}

/* Responsive Adjustments */
@media screen and (max-width: 1200px) {
    .about-section {
        flex-direction: column;
        align-items: center;
    }
    .about-image-content, .about-text-content {
        width: 100%;
    }
    .about-image1 {
        height: auto; /* Adjust height for responsiveness */
    }
    .about-description {
        margin-left: 20px;
        margin-right: 20px;
    }
}

@media screen and (max-width: 600px) {
    .about-title {
        font-size: 32px;
        text-align: center;
    }
    .about-description {
        font-size: 18px;
        margin-left: 10px;
        margin-right: 10px;
        text-align: center;
    }
    .about-text-title {
        font-size: 20px;
        text-align: center;
    }
    .about-text-sTitle {
        font-size: 16px;
    }
    .about-section {
        padding: 16px;
        gap: 16px;
    }
    .about-text-content {
        width: 100%;
    }
    .about-image-content {
        width: 100%;
    }
    .about-image1 {
        width: 100%;
        height: auto;
    }
}
