/* StatePage.css */

/* Default styles for the city list */
.city-list {
  list-style-type: none;
  padding: 0;
  margin-top: 20px; /* Add margin bottom for separation */
  display: flex;
  flex-wrap: wrap; /* Ensure items wrap onto multiple lines */
}

.city-list li {
  flex-basis: calc(33.33% - 10px); /* Each column takes 33.33% width with a small gap in between */
  cursor: pointer;
  background-color: #f0f0f0;
  border-radius: 10px;
  text-align: center;
  transition: background-color 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease;
  padding: 20px;
  font-size: 18px;
  margin-bottom: 10px; /* Add margin between each city in the list */
  margin-right: 10px; /* Add margin to the right of each item */
  border: 1px solid #ccc; /* Add border around each list item */
}

.city-list li:last-child {
  margin-right: 0; /* Remove margin from the last item to prevent unnecessary space */
}

.city-list li:hover {
  background-color: #24aa3d;
  transform: scale(1.1);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
}

/* Media query for mobile view */
@media (max-width: 600px) {
  .city-list li{
    flex-basis: 100%; /* Make each city item take up 100% width */
    margin-top: 20px; /* Add margin bottom for separation */
    margin-right: 0; /* Remove right margin */
    margin-bottom: 10px; /* Add margin bottom for separation */
  }
}
