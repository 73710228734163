@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.cards-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.custom-card {
  padding: 20px;
  margin: 20px;
  border: 1px solid #070e10;
  border-radius: 20px;
  overflow: hidden;
  background-color: #000;
  color: #fff;
  max-width: 300px;
  height: 300px;
  transition: transform 0.3s, box-shadow 0.3s;
}

.custom-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

/* Specific styling for the second card */
.custom-card:nth-child(2) {
  max-width: 350px;
  height: 350px;
}

@media screen and (max-width: 900px) {
  .custom-card {
    max-width: 100%;
    height: auto;
  }

  .cards-container {
    flex-direction: column;
    align-items: center;
  }
}

@media screen and (max-width: 600px) {
  .custom-card {
    max-width: 100%;
    height: auto;
  }
}
