.article-content-container {
    display: flex;
    margin-top: 30px;
    flex-direction: column;
}

.article-content {
    position: relative;
    margin-bottom: 20px;
}

.article-content.collapsed {
    max-height: 100px; /* Set a height limit for collapsed state */
    overflow: hidden;
    position: relative;
}

.article-content.expanded {
    max-height: none; /* No height limit for expanded state */
}

.article-content.collapsed::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 20px;
    background: linear-gradient(to top, white, transparent);
}

.article-content.collapsed .remaining-content {
    display: none;
}

.article-content.expanded .remaining-content {
    display: block;
}



.article-image img {
    float: left;
    margin-right: 20px;
    width: 500px; /* Set a specific width for the images */
    height: auto;
    display: block;
    object-fit: cover; /* Ensures the image covers the set dimensions without distortion */
}

.article-image-left img {
    float: left;
    margin-right: 20px;
    width: 500px; /* Set a specific width for the images */
    height: auto;
    display: block;
    object-fit: cover; /* Ensures the image covers the set dimensions without distortion */
}


.article-content-container {
    display: flex;
    flex-wrap: wrap;
}

.article-content {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.article-image {
    float: left;
    margin-right: 15px;
    width: 400px;
}

.remaining-content.collapsed {
    display: none;
}

.remaining-content.expanded {
    display: block;
}

.paragraph-container {
    display: flex;
    flex-wrap: wrap;
}

.paragraph {
    flex: 1 1 100%;
}

.paragraph img {
    float: left;
    margin-right: 15px;
    max-width: 100%;
}

