.planbg {
  padding: 20px;
  padding-top: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
  background: linear-gradient(to right, #000308, #FBFCFF);
}

.top1-heading {
  color: #ffffff;
  position: absolute;
  top: 200px;
  left: 450px; 
  padding-top: -250px;
  transform-origin: top left;
  font-size: 40px; /* Adjust font size as needed */
  white-space: nowrap; /* Prevent wrapping */
}

.top-heading {
  color: #000000;
  position: absolute;
  top: 550px;
  left: 170px; 
  padding-top: -250px;
  transform-origin: top left;
  font-size: 40px; /* Adjust font size as needed */
  white-space: nowrap; /* Prevent wrapping */
}

.second-heading {
  color: #16912f;
  position: absolute;
  top: 600px;
  left: 170px; 
  padding-top: 20px; /* Adjust as needed */
  transform-origin: top left;
  font-size: 16px;
  width: 600px; 
  height: 200px; 
  overflow: auto; 
}

.plan1-bg {
  background-image: url('../Assets/planbg.png'); /* Update with your image path */
  background-size: cover;
  background-position: center;
  margin-left: 40px;
  height: 70vh; /* Adjust height as needed */
  width: 1200px; 
  height: 400px; 
  overflow: auto; 
}

.top-heading-placeholder {
  height: 60px; /* Height of the rotated heading */
}

.price {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
}

p {
  line-height: 1.6;
  color: #666;
}

.footer {
  text-align: center;
  margin-top: 20px;
  color: #999;
}

.MuiStepIcon-root.MuiStepIcon-active {
  color: #1976d2; 
}

.dt-cards-content {
  margin-top: 300px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 40px;
}

.dt-card {
  width: 350px;
  height: 700px; 
  padding: 40px;
  background-color: #ffffff;
  border-radius: 18px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.7s, box-shadow 0.7s;
}

.dt-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);
}

.dt-card-name {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
}

.dt-card-title {
  font-size: 16px;
  color: #888;
  margin-bottom: 10px;
}

.dt-card-price {
  font-size: 18px;
  font-weight: bold;
  color: #11b738;
}

/* Responsive */
@media screen and (max-width: 768px) {
  .dt-cards-content {
    flex-direction: column;
    align-items: center;
  }

  .dt-card {
    width: 100%;
    max-width: 300px;
    height: auto; /* Adjust height for better mobile experience */
  }

  .top-heading {
    font-size: 30px; /* Adjust font size for mobile */
    left: 20px;
    top: 320px;
  }

  .second-heading {
    font-size: 14px; /* Adjust font size for mobile */
    width: 80%; /* Adjust width for mobile */
    top: 350px;
    left: 20px;
  }

  .plan1-bg {
    width: 100%; /* Adjust width for mobile */
    height: 200px; /* Adjust height for mobile */
    margin-left: 0;
  }
}
