.footer-section {
    background-color: #1B2433;
}

.footer-container {
    padding: 40px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start; /* Change to flex-start */
    gap: 12px;
    color: white;
    border-bottom: 2px solid #293241;
    border-radius: 2px;
    flex-wrap: wrap; /* Add flex-wrap */
}

/* Title */
.ft-title {
    color: #26dc1d;
    text-decoration: none;
    font-family: 'Poppins', sans-serif;
    font-size: 40px;
    font-weight: bold;
    letter-spacing: .8px;
}

.ft-sign {
    color: #1ECAB0;
    font-family: Cambria, sans-serif;
    font-size: 40px;
    font-weight: bold;
}

.ft-description {
    width: 420px;
    margin: 16px 0 40px;
    color: #D0D3DA;
    font-family: 'Rubik', sans-serif;
    font-size: 20px;
    letter-spacing: .8px;
    line-height: 1.7rem;
}

.ft-input-title {
    color: white;
    font-family: 'Rubik', sans-serif;
    font-size: 22px;
    font-weight: bold;
    letter-spacing: .8px;
}

.ft-input {
    width: 300px;
    margin: 20px 8px 0 0;
    padding: 16px 22px;
    color: white;
    background-color: #293241;
    border: 1px solid transparent;
    border-radius: 50px;
    outline: transparent;
    font-family: 'Rubik', sans-serif;
    font-size: 18px;
    letter-spacing: .8px;
}

.ft-btn {
    padding: 14px 18px;
    color: white;
    border: 2px solid transparent;
    border-radius: 50px;
    outline: transparent;
    background-color: #1A8EFD;
    font-size: 18px;
    font-family: 'Rubik', sans-serif;
    letter-spacing: .8px;
    cursor: pointer;
    transition: all .4s ease;
}

.ft-btn:hover {
    color: #1A8EFD;
    background-color: white;
    border: 2px solid #1A8EFD;
}

/* Lists */
.ft-list-title {
    margin: 16px 0;
    font-family: 'Rubik', sans-serif;
    font-size: 16px; /* Update font size to 16px */
    font-weight: bold;
    letter-spacing: .8px;
}

.ft-list-columns {
    display: flex;
    justify-content: flex-end; /* Align to the right side */
    width: 100%; /* Take full width */
}

.ft-list-items {
    list-style-type: none;
    flex: 1;
    font-size: 16px; /* Update font size to 16px */
}

.ft-list-items li {
    margin: 5px 0; /* Adjust margin */
    font-family: 'Rubik', sans-serif;
    font-size: 16px; /* Update font size to 16px */
    letter-spacing: .8px;
    color: #a5a7ac;
    transition: color 0.2s ease;
    cursor: pointer;

}

.ft-list-items li:hover {
    color: #1A8EFD;
    cursor: pointer;

}

.ft-list-items li a {
    text-decoration: none;
    color: inherit;
    padding: 0 0 2px 0;
    border-bottom: 2px dotted transparent;
    transition: border .2s ease;
    cursor: pointer;

}

.ft-list-items li a:hover {
    border-bottom: 2px dotted #1A8EFD;
    cursor: pointer;

}

/* CopyRight */
.ft-copyright {
    padding: 24px 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #cbcdd3;
    font-family: 'Rubik', sans-serif;
    font-size: 18px;
    font-weight: bold;
    letter-spacing: .8px;
}

.ft-social-links {
    list-style-type: none;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 12px;
}

.ft-social-links li a {
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid #cbcdd3;
    border-radius: 50%;
    cursor: pointer;
    transition: all .2s ease;
}

.ft-social-links li a svg {
    width: 24px;
    height: 24px;
    padding: 1px;
}

.ft-social-links li a svg path {
    fill: #cbcdd3;
}

.ft-social-links li a:hover, .ft-social-links li a:hover svg path {
    fill: #1A8EFD;
    border: 2px solid #1A8EFD;
}
.social-icons a {
    margin-right: 10px; /* Adjust the spacing as needed */
  }
  
  .social-icons a:last-child {
    margin-right: 0; /* Remove margin from the last item */
  }
  
/* Responsive */
/* Responsive */
@media screen and (max-width: 700px) {
    .footer-container {
        padding: 24px;
    }
    .ft-description {
        width: 100%;
        margin: 16px 0 24px;
    }
    .ft-input {
        width: 100%;
        margin: 16px 0;
    }
    .ft-btn {
        width: 100%;
        margin: 0 0 16px;
    }
    .ft-list-columns {
        flex-direction: column;
        width: 100%; /* Make the width 100% on smaller screens */
        align-items: flex-start; /* Align to the left side */
    }

 
    .ft-copyright {
        padding: 18px;
        display: block;
        text-align: center;
    }
    .ft-social-links {
        margin: 16px 0 0;
    }
}
