.section-container {
  width: 100%;
  overflow: hidden;
}

.hero-section {
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(to right, #000308, #FBFCFF);
  height: 90vh;
}

.hero1-section {
  width: 60%;
  margin-right: 100px;
  margin-top: 20px;
}

.text-headline {
  margin-bottom: 20px;
  color: rgb(17, 151, 20);
  font-family: 'Rubik', sans-serif;
  font-size: 22px;
  font-weight: bold;
  letter-spacing: .7px;
}

.text-title {
  width: 500px;
  color: rgb(15, 7, 7);
  font-family: 'Poppins', sans-serif;
  font-size: 40px;
  font-weight: bold;
}

.text-descritpion {
  width: 475px;
  margin: 32px 0;
  color: rgb(0, 0, 0);
  font-family: 'Rubik', sans-serif;
  font-size: 18px;
  letter-spacing: .7px;
  line-height: 1.6rem;
}

.text-appointment-btn {
  padding: 14px 20px;
  color: rgb(31, 23, 23);
  border: 1px solid transparent;
  border-radius: 28px;
  outline: transparent;
  background-color: #154a19;
  font-size: 18px;
  font-family: 'Rubik', sans-serif;
  letter-spacing: .8px;
  cursor: pointer;
  transition: all .4s ease;
}

.text-appointment-btn:hover {
  color: #1A8EFD;
  background-color: transparent;
  border: 1px solid #1A8EFD;
}

.text-stats {
  margin-top: 40px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 48px;
}

.text-stats-container {
  text-align: center;
}

.text-stats-container > p {
  color: rgb(164, 155, 139);
  font-family: 'Poppins', sans-serif;
  font-size: 22px;
  letter-spacing: .7px;
}

.text-stats-container p:first-child {
  margin-bottom: 8px;
  color: #2d8958;
  font-family: 'Rubik', sans-serif;
  font-size: 32px;
  font-weight: bold;
}



/* Responsive */
@media screen and (max-width: 900px) {
  .hero-section {
    height: auto;
    padding: 20px;
    flex-direction: column;
  }
  .hero1-section {
    width: 90%;
    margin-right: 20px;
    margin-top: 50px;
    padding: 0 20px;
  }
  .text-title, .text-descritpion {
    width: 100%;
  }
  .text-stats {
    flex-direction: column;
    gap: 20px;
  }
}

@media screen and (max-width: 00px) {
  .text-headline {
    font-size: 20px;
  }
  .text-title {
    font-size: 28px;
  }
  .text-descritpion {
    font-size: 16px;
  }
  .text-appointment-btn {
    font-size: 16px;
  }
  .text-stats {
    gap: 18px;
  }
  .text-stats-container > p {
    font-size: 16px;
  }
  .text-stats-container p:first-child {
    font-size: 22px;
  }

}
